import React from 'react';
import { useSelector } from 'react-redux';
import { Tag, Tooltip } from 'antd';
import { _noop } from 'underscore-es';
import { ArrowForwardOutlined, Create } from '@material-ui/icons';
import { groupsSelector, topicsSelector } from '../../redux/selectors/tags';
import {
  formatTimestampToDate,
  trimTextIfNeeded,
} from '../Drafts/Components/_utils';
import {
  CardHeader,
  Button,
  ButtonGroup,
} from '../Drafts/Components/_CardUI/Card.styled';
import {
  Card,
  Container,
  Description,
  Name,
  Wrapper,
} from './AudienceCard.styled';

const useTags = audience => {
  const topics = useSelector(topicsSelector);
  const groups = useSelector(groupsSelector);

  const selectedTopicsNames = audience.topicIds
    .map(topicId => {
      const selectedTopic = topics.find(topic => topic.id === topicId);
      return selectedTopic?.name;
    })
    .filter(name => name);

  const selectedGroupIds = [
    ...audience.selectedGroupIds,
    ...audience.requiredGroupIds,
    ...audience.excludedGroupIds,
  ];

  const selectedGroupsNames = selectedGroupIds.map(groupId => {
    const selectedGroup = groups.find(group => group.id === groupId);
    return selectedGroup?.name;
  });

  const selectedFiltersNames = audience.filterData
    ? Object.keys(audience.filterData)
    : [];

  return {
    topics: [...new Set(selectedTopicsNames)],
    groups: selectedGroupsNames,
    filters: selectedFiltersNames,
  };
};

const useLabels = audience => {
  const { topics, groups, filters } = useTags(audience);
  const topicsTooltipText = topics?.map(topicName => (
    <div key={`${audience.name}-${topicName}`}>{topicName}</div>
  ));

  const groupsTooltipText = groups?.map(groupName => (
    <div key={`${audience.name}-${groupName}`}>{groupName}</div>
  ));

  const filtersTooltipText = filters?.map(filterName => (
    <div key={`${audience.name}-${filterName}`}>{filterName}</div>
  ));

  const getName = (count, name) => {
    if (count > 1) return `${count} ${name}s`;
    return `${count} ${name}`;
  };

  return (
    <>
      {topics.length ? (
        <Tooltip title={topicsTooltipText}>
          <Tag>{getName(topics.length, 'Topic')}</Tag>
        </Tooltip>
      ) : null}
      {groups.length ? (
        <Tooltip title={groupsTooltipText}>
          <Tag>{getName(groups.length, 'Group')}</Tag>
        </Tooltip>
      ) : null}
      {filters.length ? (
        <Tooltip title={filtersTooltipText}>
          <Tag>{getName(filters.length, 'Filter')}</Tag>
        </Tooltip>
      ) : null}
    </>
  );
};

const AudienceCard = ({ audience, onClick = _noop, header = null, onEdit }) => {
  return (
    <Card>
      <CardHeader>{header}</CardHeader>
      <Card.Body>
        <Container>
          {audience.name ? (
            <Name>{trimTextIfNeeded(audience.name, 25)}</Name>
          ) : null}
          {audience.description ? (
            <Description>
              Description: {trimTextIfNeeded(audience.description, 25)}
            </Description>
          ) : null}
        </Container>
        <Wrapper>{useLabels(audience)}</Wrapper>
        <Container>
          <Card.Date>
            Created: {formatTimestampToDate(audience.createdAt)}
          </Card.Date>
          <Card.Date>
            Last updated: {formatTimestampToDate(audience.updatedAt)}
          </Card.Date>
        </Container>
        <ButtonGroup>
          <Tooltip title="Opens this Saved Audience for editing">
            <Button onClick={onEdit} light>
              Edit <Create style={{ fontSize: '13px' }} />
            </Button>
          </Tooltip>
          <Tooltip title="Opens a new draft using this Saved Audience">
            <Button onClick={onClick}>
              Use <ArrowForwardOutlined style={{ fontSize: '13px' }} />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Card.Body>
    </Card>
  );
};

export default AudienceCard;
