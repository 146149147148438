import moment from 'moment';
import axios from 'axios';
import { _pick } from 'underscore-es';
import api from '../../utils/andromeda-api';

const CORE_TEMPLATE_DATA_FIELDS = [
  'name',
  'html',
  'design',
  'subject',
  'replyTo',
  'sender',
  'senderName',
  'preheader',
];

export const getCoreTemplateData = audience => {
  return _pick(audience, CORE_TEMPLATE_DATA_FIELDS);
};

/*****************Template CRUD *******************************/
export async function getTemplateBy_id(templateId) {
  if (!templateId) return null;
  const response = await api.get(`/templates/getTemplateBy_id/${templateId}`);

  return response.data.result;
}

export async function saveNewTemplate(data) {
  //Templates can be saved in 2 ways, saving a design from a draft as a template, and creating a template from scratch.
  //Templates created from drafts will require a draftId and will not have any design. Otherwise creating from scratch requires no draftId.
  if (!data.draftId && !data.design)
    throw new Error('Missing draftId to copy template from.');

  const response = await api.post('/templates/saveNewTemplate', {
    ...data,
    saveTime: moment().unix(),
  });
  return response.data.result;
}

export async function createUserTemplate(data) {
  return saveNewTemplate({
    design: '{}',
    name: 'Untitled',
    ...data,
    archived: false,
  });
}

export async function updateTemplate(data) {
  if (!data._id) throw new Error('Template id should be provided');
  await api.post('/templates/updateTemplate', {
    ...data,
    saveTime: moment().unix(),
  });
}

export async function getTemplates(orgAcronym) {
  const { data } = await axios.get(`${process.env.REACT_APP_templatesLambda}`);
  if (orgAcronym !== 'SABOR') {
    return data
      .map(template => JSON.parse(template))
      .filter(template => template.name !== 'Code of Ethics Compliance Notice');
  }
  return data.map(template => JSON.parse(template));
}

export async function getTemplateById(templateIdentifier) {
  if (!templateIdentifier) return null;
  try {
    console.log('CALL::', templateIdentifier);
    const response = await api.get(
      `/templates/getTemplateById/${templateIdentifier}`
    );

    return response.data.result;
  } catch (e) {
    console.error('Error retrieving template [getTemplateById]', e);
    return null;
  }
}

export async function getUserTemplates({
  orgId,
  sortby = '-saveTime',
  skip,
  limit,
  filter,
  searchValue,
}) {
  const { data } = await api.get(
    `/templates/getOrgTemplates/${orgId}/${sortby}`,
    { params: { skip, limit, filter, searchValue } }
  );

  return data;
}

export const moveUserTemplateToOtherOrgAction = async template => {
  if (!template._id) return;
  await api.post('/templates/moveUserTemplateToOtherOrg', template);
};
