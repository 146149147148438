import styled from 'styled-components';
import SendOutlined from '@ant-design/icons/SendOutlined';

const SendIcon = styled(SendOutlined)`
  && {
    font-size: 50px;
    padding: 20% 20px;

    @media (min-width: 1820px) {
      padding: 10% 20px;
    }
  }
`;

export const EmptyCardSendIcon = styled(SendOutlined)`
  && {
    font-size: 70px;
    color: #027dd7;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-left: 20px;
  }
`;

export default SendIcon;
