import { useEffect, useState } from 'react';

const useCountCardsPerRow = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const resetWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resetWindowWidth);

    return () => {
      window.removeEventListener('resize', resetWindowWidth);
    };
  }, []);

  let numOfCardsPerRow = 2;
  if (windowWidth >= 1790) {
    numOfCardsPerRow = 4;
  } else if (windowWidth >= 1430) {
    numOfCardsPerRow = 3;
  }
  return numOfCardsPerRow;
};

export default useCountCardsPerRow;
