import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: ${props => props.justifyContent || 'center'};
  width: 100%;
`;

export const FullLengthContainer = styled.div`
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const MessageContainer = styled.div`
  width: 100%;
  padding: 15px 25px 0;
`;

export const Text = styled.span`
  padding-left: 10px;
`;

export const TempNotificationText = styled.div`
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  color: #00307e;
  padding: 15px 25px 0;
  text-align: right;
  @media (max-width: 960px) {
    padding: 15px 0 0;
  }
`;
