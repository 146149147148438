import styled from 'styled-components';

export const Card = styled.div`
  font-family: Poppins, sans-serif;
  min-width: 340px;
  max-width: calc((100% - 400px) * (1 / 3));
  aspect-ratio: 8/5;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 4px;
  transition: 400ms ease;
  overflow: hidden;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  &:hover {
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.8);
  }
  @media (min-width: 2200px) {
    width: calc((100% - 300px) * (1 / 4));
    aspect-ratio: 2/1;
  }
`;

Card.Left = styled.div`
  background-color: silver;
`;

Card.Body = styled.div`
  flex: 0 0 60%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

Card.Thumbnail = styled.div`
  flex: 0 0 40%;
  overflow: hidden;
`;

Card.Date = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-style: italic;
`;

Card.Name = styled.h5`
  font-weight: bold;
  line-height: 16px;
`;

Card.Subject = styled.h5`
  line-height: 16px;
`;

Card.AB = styled.span`
  color: #61ac04;
  text-align: left;
  font-size: 12px;
  padding: 0 10px;
  font-weight: bold;
  border-radius: 5px;
  background-color: #f7ffe4;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

CardHeader.Left = HeaderActions;
CardHeader.Right = HeaderActions;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: auto;
  gap: 8px;
`;

export const Button = styled.button`
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  padding: 6px 14px;
  border-radius: 2px;
  text-align: center;
  align-self: flex-end;
  color: ${({ light }) => (light ? '#007ade' : 'white')};
  background-color: ${({ light }) => (light ? 'white' : '#007ade')};
  border: 1px solid #007ade;
  transition: all 0.3s ease-in;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: auto;
  &:hover,
  &.active {
    cursor: pointer;
    color: ${({ light }) => (light ? 'white' : '#007ade')};
    background-color: ${({ light }) => (light ? '#007ade' : 'white')};
    border: 1px solid #007ade;
  }
`;

export const CancelButton = styled.button`
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  padding: 6px 14px;
  border-radius: 2px;
  text-align: center;
  align-self: flex-end;
  color: #ff6161;
  background-color: white;
  border: 1px solid #ff6161;
  transition: all 0.3s ease-in;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: auto;
  &:hover,
  &.active {
    cursor: pointer;
    color: white;
    background-color: #ff6161;
    border: 1px solid #ff6161;
  }
`;

export const Date = styled.div`
  color: #ffae00;
  padding: 0 10px;
  font-weight: bold;
  border-radius: 5px;
  background-color: #fff9e3;
  font-size: 12px;
`;
