import React, { useState } from 'react';
import BrushIcon from '@material-ui/icons/Brush';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { getHTMLbaseOnDesign } from '../../../../redux/services';
import { Status } from '../SavingStatus';
import ABToggleButton from './ABToggleButton';
import MessageInfo from '../MessageInfo';
import PersonalizeSelect from '../PersonalizeSelect';
import BackButton from '../BackButton';
import {
  DraftNameInput,
  Header,
  SavingStatusText,
  ScreenTitle,
  IconButton,
} from './Header.styled';
import SendDraft from '../../../ViewMessage/SendDraft';
import PreviewDesignModal from '../../Modals/PreviewDesignModal';
import IconEmailCheck from './EmailCheckIcon';

const MESSAGE_BY_SAVE_STATUS = {
  [Status.SAVING]: 'Saving...',
  [Status.SAVED]: 'All changes saved',
  [Status.UNSAVED]: 'Unsaved changes',
};

const BaseHeader = ({
  className = null,
  isTitleHidden = false,
  isSaveStatusHidden = false,
  menu,
  nextButton = null,
  backBtnTargetUrl,
  nameInputPlaceholder = 'Enter Name',
  messageInfoData,
  saveStatus,
  onUnsavedStatus,
  onNameChange,
  name,
  messageInfoRef,
  onSave,
  autosaveInterval = null,
  isDesignLoading,
  isABToggleEnabled,
  setABToggleEnabled,
  isConfirmDeleteABDraftModalVisible,
  setConfirmDeleteABDraftModalVisible,
  handleVersionChange,
  design,
}) => {
  const [isSendDraftModalOpen, setIsSendDraftModalOpen] = useState(false);
  const [isDesignPreviewModalVisible, setDesignPreviewModalVisiblity] =
    useState(false);
  const [isLoadingPreview, setLoadingPreview] = useState(false);
  const [html, setHTML] = useState('');

  const openPreviewWindow = async () => {
    setLoadingPreview(true);
    const updatedHTML = await getHTMLbaseOnDesign(design);
    setHTML(updatedHTML);
    setDesignPreviewModalVisiblity(true);

    // this is a hack to give a moment for a preview screen to get an updated html design
    setTimeout(() => {
      setLoadingPreview(false);
    }, 500);
  };

  const openSendDraftDialog = async () => {
    setIsSendDraftModalOpen(true);
  };

  return (
    <Header className={className}>
      <Header.LeftSide>
        {!isTitleHidden && (
          <ScreenTitle>
            <BackButton targetUrl={backBtnTargetUrl} />
            <ScreenTitle.Text>
              <BrushIcon /> Design
            </ScreenTitle.Text>
          </ScreenTitle>
        )}
        {menu}
        <DraftNameInput
          placeholder={nameInputPlaceholder}
          value={name}
          onChange={({ target }) => {
            onNameChange(target.value);
          }}
          onFocus={event => {
            const $input = event.target;
            setTimeout(() => {
              $input.selectionStart = $input.selectionEnd = $input.value.length;
            }, 0); // Chrome triggers focus event before setting cursor, so we should move the cursor only after it is first set
            $input.scrollTo($input.scrollWidth, 0);
          }}
          onBlur={event => {
            event.target.scrollTo(0, 0); // show the beginning of the name
          }}
        />
      </Header.LeftSide>
      <Header.RightSide>
        {!isSaveStatusHidden && (
          <SavingStatusText>
            {MESSAGE_BY_SAVE_STATUS[saveStatus]}
          </SavingStatusText>
        )}
        {isABToggleEnabled && (
          <ABToggleButton
            AB={messageInfoData.AB}
            draftId={messageInfoData._id}
            isSaving={saveStatus !== Status.SAVED}
            isDesignLoading={isDesignLoading}
            isABToggleEnabled={isABToggleEnabled}
            setABToggleEnabled={setABToggleEnabled}
            isConfirmDeleteABDraftModalVisible={
              isConfirmDeleteABDraftModalVisible
            }
            setConfirmDeleteABDraftModalVisible={
              setConfirmDeleteABDraftModalVisible
            }
            handleVersionChange={handleVersionChange}
          />
        )}
        {messageInfoRef && ( // I needed to add the following icons only for draft editor and if messageInfoRef exists
          <>
            <Tooltip title="Preview message">
              <IconButton onClick={openPreviewWindow}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send Draft">
              <IconButton onClick={openSendDraftDialog}>
                <IconEmailCheck />
              </IconButton>
            </Tooltip>
          </>
        )}
        <MessageInfo
          ref={messageInfoRef}
          data={messageInfoData}
          onUnsavedStatus={onUnsavedStatus}
          onSave={onSave}
          autosaveInterval={autosaveInterval}
        />
        <PersonalizeSelect />
        {nextButton}
      </Header.RightSide>
      {isSendDraftModalOpen && (
        <SendDraft
          setIsSendDraftModalOpen={setIsSendDraftModalOpen}
          draftData={messageInfoData}
          shouldGetUpdatedHtml={true}
          design={design}
        />
      )}
      <PreviewDesignModal
        html={isLoadingPreview ? '' : html || messageInfoData.html}
        name={name}
        isVisible={isDesignPreviewModalVisible}
        onHide={() => setDesignPreviewModalVisiblity(false)}
      />
    </Header>
  );
};

export default BaseHeader;
