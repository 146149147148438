import React from 'react';
import { Select } from 'antd';

const FilterCardsSelectDropdown = ({
  filterBy,
  activeFilter,
  setActiveFilter,
}) => {
  const Option = Select.Option;
  return (
    <Select
      value={activeFilter}
      onSelect={value => setActiveFilter(value)}
      style={{ width: '100px', textAlign: 'left' }}
      size="large"
    >
      {filterBy.map(({ name, value }) => (
        <Option key={name} value={value}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export default FilterCardsSelectDropdown;
