import styled from 'styled-components';
import { ReactComponent as LogoSVG } from './noterouter-logo.svg';

export const ImageHolder = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
`;

export const StyledLogo = styled(LogoSVG)`
  width: 25px;
  height: 25px;
`;
