import styled from 'styled-components';

export const Card = styled.div`
  font-family: Poppins, sans-serif;
  min-width: 350px;
  max-width: calc((100% - 400px) * (1 / 3));
  aspect-ratio: 8/5;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.4);
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  transition: 400ms ease;
  overflow: hidden;
  padding: 12px;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  &:hover {
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.8);
  }
  @media (min-width: 2200px) {
    width: calc((100% - 300px) * (1 / 4));
    aspect-ratio: 2/1;
  }
`;

export const Container = styled.div`
  padding: 0;
`;

export const Description = styled.p`
  font-size: 12px;
  margin: 0;
`;

export const Name = styled.h4`
  font-size: 18px;
  margin: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

Card.Body = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  height: 100%;
  flex-grow: 1;
`;

Card.Date = styled.p`
  font-size: 12px;
  line-height: 12px;
  font-style: italic;
  margin: 3px;
`;

Card.Name = styled.h5`
  font-weight: bold;
  line-height: 16px;
`;
