import React from 'react';

import { useFullScreenSpinner } from '../../../../../redux/actions/UI';
import { useSharedTemplatesQuery } from '../../../../../redux/services/queries/templates';
import TemplateCard from './TemplateCard';
import TemplateList from '../TemplateList';
import { useSelector } from 'react-redux';
import { selectedOrganizationSelector } from '../../../../../redux/selectors/organization';

const SharedTemplates = ({ onEdit }) => {
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const { data, isLoading } = useSharedTemplatesQuery(
    {},
    selectedOrganization.acronym
  );

  useFullScreenSpinner('Loading templates...', isLoading);

  const getTemplate = id => {
    const { name, design } = data.find(template => template._id === id);
    return {
      name,
      design,
      html: null,
      subject: null,
      replyTo: null,
      sender: null,
      senderName: null,
      preheader: null,
    };
  };

  const handleEdit = templateId => {
    const template = getTemplate(templateId);
    onEdit(template);
  };

  return (
    <TemplateList data={data} TemplateCard={TemplateCard} onEdit={handleEdit} />
  );
};

export default SharedTemplates;
