import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const Text = styled.span`
  font-size: 13px;
  padding-left: 20px;
`;
