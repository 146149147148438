import React, { useState, useEffect } from 'react';
import {
  fetchOrganizationTypeName,
  syncTypes,
  handleSyncRequest,
} from './utils';
import { useToast, useUpdateUIState } from '../../redux/action';
import { Button, Menu, MenuItem } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  addSyncItem,
  makeAMSSyncRequest,
  makeRapGZAMSSyncRequest,
  // makeRapGZAMSSyncRequest,
} from '../../redux/services/import';
import { useIsFeatureEnabled } from '../../libs/feature-toggles/react';
import Feature from '../../infrastructure/features';

// eslint-disable-next-line no-unused-vars
const AMSSync = ({ orgDetails, userId, refreshManageScreen }) => {
  const [enabledSyncTypes, setEnabledSyncTypes] = useState([]);
  const [orgTypeName, setOrgTypeName] = useState(null);
  const [syncMenuAnchor, setSyncMenuAnchor] = useState(false);

  const { fullLoader } = useUpdateUIState();
  const { errorToast, successToast } = useToast();
  const isEventsAndClassesFeatureEnabled = useIsFeatureEnabled(
    Feature.EVENTS_AND_CLASSES
  );

  useEffect(() => {
    if (orgDetails?.orgType) {
      filterSyncTypes();
    }
  }, [orgDetails?.orgType, orgDetails?._id]);

  const filterSyncTypes = async () => {
    const orgTypeNameRes = await fetchOrganizationTypeName(orgDetails.orgType);
    setOrgTypeName(orgTypeNameRes);

    //Only display syncs that the organization has enabled.
    const enabledSyncs = syncTypes.filter(sync => {
      if (sync.name === 'Events' && !isEventsAndClassesFeatureEnabled) {
        return false;
      }

      if (sync.name === 'List' && !orgDetails?.usingAPILists) {
        return false;
      }

      return (
        sync.enabledOrgs === '*' || sync.enabledOrgs.includes(orgTypeNameRes)
      );
    });
    setEnabledSyncTypes(enabledSyncs);
  };

  const loopSyncRequests = async (syncMessage, apiPath) => {
    const requestData = {
      skipCount: 0,
      totalImportCount: 0,
      organizationData: orgDetails,
      numberOfRetries: 0,
    };
    let runSync = true;
    do {
      const { success, finished, message, result } = await handleSyncRequest(
        apiPath,
        requestData,
        syncMessage
      );
      if (!success) {
        // end sync early if not successful
        fullLoader(false);
        errorToast(message);
        return;
      }
      fullLoader(true, message); //Update loader copy with progress
      // Update requestData to be passed into sync request again
      requestData.skipCount = result.count;
      requestData.totalImportCount = result.totalImportCount;
      requestData.numberOfRetries = result.numberOfRetries;
      // We know to stop the sync once the skip count equals the total import count
      runSync =
        !finished && requestData.skipCount !== requestData.totalImportCount;
    } while (runSync);
    successToast('Successfully completed sync.');
  };

  const createQueueItem = async (syncType, source) => {
    if (syncType === 'Committees') {
      await addSyncItem({
        organizationId: orgDetails.id,
        syncType: 'committees',
        source,
        type: 'full',
      });
      const response = await addSyncItem({
        organizationId: orgDetails.id,
        syncType: 'committeeMemberships',
        source,
        type: 'full',
      });
      return response;
    } else if (syncType === 'Events') {
      await addSyncItem({
        organizationId: orgDetails.id,
        syncType: 'eventsAndClasses',
        source,
        type: 'full',
      });
      await addSyncItem({
        organizationId: orgDetails.id,
        syncType: 'eventRegistration',
        source,
        type: 'full',
      });
      const response = await addSyncItem({
        organizationId: orgDetails.id,
        syncType: 'course',
        source,
        type: 'full',
      });
      return response;
    }
    const response = await addSyncItem({
      organizationId: orgDetails.id,
      syncType,
      source,
      type: 'full',
    });
    return response;
  };

  const isQueueSyncEnabled = () => {
    const queueSyncEnabledOrgIds = process.env.REACT_APP_QUEUE_ENABLED_ORG_IDS
      ? process.env.REACT_APP_QUEUE_ENABLED_ORG_IDS.split(',')
      : [];
    return queueSyncEnabledOrgIds.includes(orgDetails.id);
  };

  const initiateAMSSync = async (syncType, source) => {
    if (source === 'ramco' && syncType === 'List') {
      const response = await makeAMSSyncRequest(
        `ramco/list/all?orgId=${orgDetails.id}&userId=${userId}`
      );
      if (response.success) successToast(response.message, 10);
      else errorToast(response.message, 10);
      fullLoader(false);
    } else if (isQueueSyncEnabled()) {
      const response = await createQueueItem(syncType, source);
      if (response.success) successToast(response.message, 10);
      else errorToast(response.message, 10);
      fullLoader(false);
    } else if (source === 'ramco') {
      const response = await makeAMSSyncRequest(
        `ramco?orgId=${orgDetails.id}&userId=${userId}&type=${syncType}`
      );
      if (response.success) successToast(response.message, 10);
      else errorToast(response.message, 10);
      fullLoader(false);
    } else if (source === 'tangilla') {
      const response = await makeAMSSyncRequest(
        `tangilla?orgId=${orgDetails.id}&userId=${userId}`
      );
      return response;
    } else if (source === 'gz' || source === 'magic') {
      if (syncType === 'Membership' || syncType === 'Billing') {
        const response = await makeRapGZAMSSyncRequest('import/member/', {
          syncType: syncType,
          source,
          organizationId: orgDetails.id,
        });
        if (response.success) successToast(response.message, 10);
        else errorToast(response.message, 10);
      } else if (syncType === 'Offices') {
        const response = await makeRapGZAMSSyncRequest('import/office/', {
          source,
          organizationId: orgDetails.id,
        });
        if (response.success) successToast(response.message, 10);
        else errorToast(response.message, 10);
      } else if (syncType === 'Committees') {
        //Committees sync requires multiple syncs to fetch all data properly
        await makeRapGZAMSSyncRequest('import/committee/all', {
          source,
          organizationId: orgDetails.id,
        });
        const response = await makeRapGZAMSSyncRequest(
          'import/committee/members',
          {
            source,
            organizationId: orgDetails.id,
          }
        );
        if (response.success) successToast(response.message, 10);
        else errorToast(response.message, 10);
      } else if (syncType === 'Events') {
        //Events sync requires multiple syncs to fetch all data properly
        await loopSyncRequests(
          'events and classes',
          `import/events/eventsandclasses/${source}`
        );
        await loopSyncRequests(
          'event registrations',
          `import/events/eventregistrations/${source}`
        );
        await loopSyncRequests('courses', `import/events/courses/${source}`);
        //After the sync has been run, refresh the contact/topic/group data on display
        await refreshManageScreen(orgDetails.id, true);
      }
      fullLoader(false);
    }
  };

  const handleSyncActivation = async type => {
    try {
      fullLoader(true, 'Initializing AMS Sync');

      const { url, key } = orgDetails;

      if (!key) {
        errorToast('Error initializing AMS sync, missing API Key');
        fullLoader(false);
        return;
      }

      if (!url && orgTypeName !== 'ramco' && orgTypeName !== 'tangilla') {
        errorToast('Error running AMS sync, missing AMS url');
        fullLoader(false);
        return;
      }

      await initiateAMSSync(type, orgTypeName);
    } catch (e) {
      fullLoader(false);
      console.error(`Error running AMS sync - ${e}`);
      errorToast('AMS sync failed to run');
    }
  };

  return (
    <>
      <Button
        id="sync-activation-menu-button"
        aria-controls="sync-menu"
        aria-haspopup="true"
        aria-expanded={syncMenuAnchor ? 'true' : undefined}
        size={'small'}
        variant="outlined"
        startIcon={<CloudDownloadIcon />}
        onClick={event => setSyncMenuAnchor(event.currentTarget)}
        style={{ background: '#007ade', color: '#fff', borderColor: '#007ade' }}
      >
        Sync
      </Button>
      <Menu
        className={'syncMenuContainer'}
        id="sync-menu"
        aria-labelledby="sync-activation-menu-button"
        anchorEl={syncMenuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(syncMenuAnchor)}
        onClose={() => setSyncMenuAnchor(null)}
      >
        {enabledSyncTypes.map(sync => {
          return (
            <MenuItem
              onClick={() => {
                setSyncMenuAnchor(null);
                handleSyncActivation(sync.name);
              }}
              key={sync.name}
            >
              {sync.display}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default AMSSync;
