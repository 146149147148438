import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { ArrowForwardOutlined, Create, StarRounded } from '@material-ui/icons';
import { userDetailSelector } from '../../../../redux/selector';
import { updateTemplate, getTemplateBy_id } from '../../../../redux/services';
import { toast } from '../../../../redux/action';
import { Card, CardHeader, Button, ButtonGroup } from '../_CardUI/Card.styled';
import {
  FavoriteButton,
  ArchiveButton,
  UnarchiveButton,
} from '../_CardUI/CardActionButtons.styled';
import { formatDate, trimTextIfNeeded, formatTimestampToDate } from '../_utils';
import MoveToOtherOrgBtn from './UserTemplate.styled';
import MoveUserTemplateModal from './MoveUserTemplateModal';
import Thumbnail from '../Thumbnail';

const UserTemplateCard = ({
  template,
  archive,
  unarchive,
  onClick,
  removeTemplate,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(userDetailSelector);
  const [isMoveUserTemplateModalVisible, setMoveUserTemplateModalVisibility] =
    useState(false);

  const applyTemplateToDraft = queryString.parse(location.search).apply === '1';

  const [starred, setStarred] = useState(template.starred);
  const starredTitle = starred ? 'Remove from Favorites' : 'Add to Favorites';

  const toggleFavoriteButton = async isFavorite => {
    try {
      await updateTemplate({ ...template, starred: isFavorite });

      let text = '';
      if (isFavorite) {
        setStarred(true);
        text = 'added to';
      } else {
        setStarred(false);
        text = 'removed from';
      }

      dispatch(toast('success', `Template ${text} Favorites`));
    } catch (e) {
      dispatch(toast('error', `Failed to update template. ${e.message}`));
    }
  };

  const goToEdit = async () => {
    try {
      const templateData = await getTemplateBy_id(template._id);
      history.push(`/dashboard/sendmessage?template=${templateData._id}`);
    } catch (e) {
      dispatch(toast('error', 'Failed to fetch template'));
    }
  };

  const getActionIcons = () => {
    if (applyTemplateToDraft) return '';

    return (
      <>
        {template.archived ? (
          <>
            <CardHeader.Left />
            <CardHeader.Right>
              <UnarchiveButton onClick={unarchive} />
            </CardHeader.Right>
          </>
        ) : (
          <>
            <CardHeader.Left />
            <CardHeader.Right>
              <Tooltip title={starredTitle}>
                <FavoriteButton
                  as={StarRounded}
                  $starred={starred ? true : false}
                  onClick={() => {
                    if (starred) {
                      toggleFavoriteButton(false);
                    } else {
                      toggleFavoriteButton(true);
                    }
                  }}
                />
              </Tooltip>
              {user.multiOrgUser && (
                <Tooltip title="Move Template to Another Organization">
                  <MoveToOtherOrgBtn
                    onClick={() => {
                      setMoveUserTemplateModalVisibility(true);
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip title="Archive">
                <ArchiveButton onClick={archive} />
              </Tooltip>
            </CardHeader.Right>
          </>
        )}
      </>
    );
  };

  return (
    <Card>
      <Thumbnail html={template.html} />
      <Card.Body>
        <CardHeader>{getActionIcons()}</CardHeader>
        <Tooltip
          title={`Create a new Draft with Template: "${template.name}"`}
        />
        <Card.Name>{trimTextIfNeeded(template.name, 22)}</Card.Name>
        {template.subject ? (
          <Card.Subject>
            Subject: {trimTextIfNeeded(template.subject, 15)}
          </Card.Subject>
        ) : null}
        <Card.Date>
          Created: {formatTimestampToDate(template.createdAt)} <br />
          Last Updated: {formatDate(template.saveTime)}
        </Card.Date>
        <ButtonGroup>
          <Tooltip title="Opens this template for editing">
            <Button onClick={goToEdit} light>
              Edit <Create style={{ fontSize: '13px' }} />
            </Button>
          </Tooltip>
          <Tooltip title="Opens a new draft using this template">
            <Button onClick={onClick}>
              Use <ArrowForwardOutlined style={{ fontSize: '13px' }} />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Card.Body>
      {isMoveUserTemplateModalVisible && (
        <MoveUserTemplateModal
          isOpen={isMoveUserTemplateModalVisible}
          setOpen={setMoveUserTemplateModalVisibility}
          template={template}
          removeTemplate={removeTemplate}
        />
      )}
    </Card>
  );
};

export default UserTemplateCard;
