import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { selectedOrganizationSelector } from '../../../../redux/selector';

const CardsSearch = ({
  setCards,
  setLoading,
  searchValue,
  setSearchValue,
  getCards,
}) => {
  const selectedOrg = useSelector(selectedOrganizationSelector);
  const debounceTimeout = 800;

  const debouncedFetchCardsData = useMemo(() => {
    return debounce(async value => {
      if (!value) {
        setCards([]);
        return;
      }

      try {
        const { result } = await getCards({
          orgId: selectedOrg.id,
          searchValue: value,
        });

        setCards(result);
        setLoading(false);
      } catch (err) {
        console.error('Error loading cards', err);
      }
    }, debounceTimeout);
  }, [selectedOrg.id, setCards, getCards, debounceTimeout]);

  useEffect(() => {
    debouncedFetchCardsData(searchValue);

    return () => {
      debouncedFetchCardsData.cancel();
    };
  }, [searchValue, debouncedFetchCardsData]);

  const handleOnChange = e => {
    const value = e.target.value;
    setLoading(!!value);
    setSearchValue(value);
    if (!value) {
      setCards([]);
    }
  };

  return (
    <Input
      placeholder="Search..."
      prefix={<SearchOutlined />}
      size="large"
      value={searchValue}
      onChange={handleOnChange}
      style={{
        width: '180px',
      }}
      allowClear
    />
  );
};

export default CardsSearch;
